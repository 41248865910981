import * as exchStyles from '../exchangeDetail.module.css'
import React, { useEffect, useState, useCallback } from 'react'
import loadable from '@loadable/component'
import MetaData from '../../components/MetaData'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Section, Title, Text, Box, Input } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import { useSelector, useDispatch } from 'react-redux'
import * as exchDataActions from '../../store/actions/exchDataActions'
import { debounce } from 'lodash'
import EditIcon from '../../assets/image/svg/ic_pencil.inline.svg'
import Link from '../../components/Link'
import { useTranslation } from 'react-i18next'
import NewTabIcon from '../../assets/image/svg/ic_newtab16.inline.svg'
import { useStaticQuery, graphql } from 'gatsby'
import { suggestEdit } from '../../store/actions/userActions'

const ExchMarketsTable = loadable(() =>
  import('../../components/Table/ExchMarketsTable')
)

const ExchMarketsTableWithFooter = loadable(() =>
  import('../../components/Table/ExchMarketsTableWithFooter')
)
const LatestNews = loadable(() => import('../../sections/news/LatestNews'))
const FAQ = loadable(() => import('../faqSection'))
const Select = loadable(() => import('../../components/Table/RangeSelector'))

const ExchDetail = ({ location }) => {
  const { t, i18n, ready } = useTranslation( ['exchanges', 'tables'] , { useSuspense: false });

  const selectedExch = useSelector(state => state.exchData.exchDetail)
  const dispatch = useDispatch()
  // console.log('JSON', selectedExch.exchJSON)

  let locationArr = location.pathname.split('/')
  let locationExchSlug = locationArr[3]

  useEffect(() => {
    dispatch(exchDataActions.fetchExchDetail(locationExchSlug))
  }, [dispatch, locationExchSlug])

  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  const btcExchRate = useSelector(state => state.currencies.btcExchRate)
  const currSign = useSelector(state => state.user.currSign)
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const totalRecords = useSelector(state => state.exchData.totalRecords)
  const tableRows = useSelector(state => state.user.tableRows)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  let exchVol = selectedExch.exchVolume24 * btcExchRate

  let newToken =
    /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie) !== null
      ? /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie)[1]
      : ''

  // SUGGEST EDIT CODE
  const [openTextArea, setOpenTextArea] = useState(false)
  const [openFAQTextArea, setOpenFAQTextArea] = useState(false)
  const [inputValue, setInputValue] = useState(null)
  const request = debounce(value => {
    setInputValue(value)
  }, 1000)
  const debouceRequest = useCallback(value => request(value), [request])
  const onChange = e => {
    debouceRequest(e.target.value)
  }

  const editDescOptions = [
    { label: ready && `${t(`missingData`)}`, value: 0 },
    { label: ready && `${t(`incorrectData`)}`, value: 1 },
    { label: ready && `${t(`other`)}`, value: 2 },
  ]
  const [editType, setEditType] = useState(0)

  const sendUserInput = () => {
    if (inputValue !== null) {
      dispatch(suggestEdit(newToken, editType, location.href, inputValue))
      setOpenTextArea(false)
      setOpenFAQTextArea(false)
      setInputValue(null)
    } else {
      alert('please enter a valid message!')
    }
  }

  if (selectedExch !== false) {
    return ready && (
      <>
        <MetaData page="exchSlug" param={{exchName: selectedExch.exchName, locationExchSlug}}  />
        <PageWrapper headerDark footerDark>
          <Section bg={userDarkMode ? 'black' : 'bg'} pb={[4, null, '40px', 5]}>
            <Container>
              <Row>
                <Col className="d-flex justify-content-between my-2" lg="12">
                  <Link to={`/${lang}/exchanges/${selectedExch.prevSlug}/`}>
                    <span
                      style={{
                        cursor: 'pointer',
                        color: userDarkMode ? '#FAFAFA' : 'black',
                      }}
                    >
                      {selectedExch.prevSlugName
                        ? '← ' + selectedExch.prevSlugName.toUpperCase()
                        : ''}
                    </span>
                  </Link>
                  <Link to={`/${lang}/exchanges/${selectedExch.nextSlug}/`}>
                    <span
                      style={{
                        cursor: 'pointer',
                        color: userDarkMode ? '#FAFAFA' : 'black',
                      }}
                    >
                      {selectedExch.nextSlugName
                        ? selectedExch.nextSlugName.toUpperCase() + ' →'
                        : ''}
                    </span>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Box className="d-flex align-items-baseline mt-3 mb-4">
                    <img
                      className={exchStyles.exchangeLogo}
                      src={
                        process.env.WWW_URL +
                        `/assets/img/exchange/${selectedExch.exchCode}-icon.png`
                      }
                      alt={`${selectedExch.exchName} Logo`}
                    />
                    <Title
                      color={userDarkMode ? '#FAFAFA' : 'black'}
                      className="ml-2 mb-1"
                      variant="hero-smaller"
                    >
                      {selectedExch.exchName}
                    </Title>
                    <Text
                      color={userDarkMode ? 'lightShade' : 'darkShade'}
                      className="ml-2"
                    >
                      {selectedExch.exchCode}
                    </Text>
                  </Box>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Row>
                    <Col xs="12" sm="3" md="3">
                      <Text
                        color={userDarkMode ? 'lightShade' : 'darkShade'}
                        variant="small"
                      >
                        {t('currencies')}
                      </Text>
                      <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                        {selectedExch.exchNumCurrencies
                          ? selectedExch.exchNumCurrencies.toLocaleString(
                              undefined
                            )
                          : ''}
                      </Text>
                    </Col>
                    <Col xs="12" sm="3" md="3">
                      <Text
                        color={userDarkMode ? 'lightShade' : 'darkShade'}
                        variant="small"
                      >
                        {t('markets')}
                      </Text>
                      <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                        {selectedExch.exchNumMarkets
                          ? selectedExch.exchNumMarkets.toLocaleString(
                              undefined
                            )
                          : ''}
                      </Text>
                    </Col>
                    <Col sm="4" md="6">
                      <Text
                        color={userDarkMode ? 'lightShade' : 'darkShade'}
                        variant="small"
                      >
                        {t('volume')}
                      </Text>
                      <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                        {currSign ? currSign : '$'}{' '}
                        {exchVol
                          ? exchVol.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''}
                      </Text>
                    </Col>
                    <Col lg="12">
                      <Text
                        color={userDarkMode ? 'lightShade' : 'darkShade'}
                        variant="small"
                      >
                        {t('weSupport')}
                      </Text>
                      <Text color={userDarkMode ? '#FAFAFA' : 'black'} mb={4}>
                        Charting
                        {selectedExch.exchBalanceEnabledV2
                          ? ', Balance tracking'
                          : ''}
                        {selectedExch.exchTradeEnabledV2 &&
                        selectedExch.hasSpotTrading
                          ? ', Spot trading'
                          : ''}
                        {selectedExch.exchTradeEnabledV2 &&
                        selectedExch.hasFuturesTrading
                          ? ', Futures trading'
                          : ''}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col lg="6" mb={4}>
                  <Text
                    color={userDarkMode ? 'lightShade' : 'darkShade'}
                    variant="small"
                    mb={4}
                  >
                    {t(`${selectedExch.exchCode}Faqs.itIs`).includes(
                      'Faqs'
                    )
                      ? ''
                      : t(`${selectedExch.exchCode}Faqs.itIs`)}
                  </Text>
                  {userSessionExpired === false ? (
                    <div className="mt-3 mb-3">
                      <EditIcon />
                      <Button
                        onClick={() => setOpenTextArea(true)}
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                        }}
                      >
                        <Text color="#3377C6" variant="small">
                          {t(`suggestEdit`)}
                        </Text>
                      </Button>
                    </div>
                  ) : null}

                  {openTextArea === true ? (
                    <Row>
                      <Select
                        className="mb-3 mt-3"
                        isSearchable={false}
                        options={editDescOptions}
                        borderRadius={0}
                        defaultValue={editDescOptions[1]}
                        onChange={option => setEditType(option.value)}
                        components={{ IndicatorSeparator: () => null }}
                        userDarkMode={userDarkMode}
                      />
                      <Input
                        onChange={e => onChange(e)}
                        placeholder="Enter your suggestion..."
                      />
                      <Button
                        className="mt-3"
                        onClick={() => sendUserInput(inputValue)}
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                        }}
                      >
                        <Text color="#3377C6" variant="small">
                          {t(`send`)}
                        </Text>
                      </Button>
                    </Row>
                  ) : null}
                  {selectedExch.exchUrl ? (
                    <a
                      rel="noreferrer noopener"
                      target="_blank"
                      href={selectedExch.exchUrl}
                    >
                      <span
                        style={{ color: userDarkMode ? '#FAFAFA' : 'black' }}
                      >
                        {selectedExch.exchUrl}{' '}
                      </span>
                      <NewTabIcon
                        style={{
                          filter: userDarkMode ? 'invert(40%)' : 'invert(50%)',
                          marginBottom: '2px',
                          marginLeft: '4px',
                          width: '16px',
                          height: '16px',
                        }}
                        aria-label={ready && t('newTab')}
                        role="img"
                      />
                    </a>
                  ) : null}
                </Col>
              </Row>
            </Container>
          </Section>
          <Section bg={userDarkMode ? 'black' : 'bg'} py={[0, null, null, 0]}>
            <Container>
              <Row>
                <Col lg="12">
                {
                  totalRecords < tableRows ?
                    <ExchMarketsTable
                      exchSlug={locationExchSlug}
                      exchCode={selectedExch.exchCode}
                      exchName={selectedExch.exchName}
                    />
                    :
                    <ExchMarketsTableWithFooter
                      exchSlug={locationExchSlug}
                      exchCode={selectedExch.exchCode}
                      exchName={selectedExch.exchName}
                    />
                }
                </Col>
              </Row>
            </Container>
          </Section>
          <Section bg={userDarkMode ? 'black' : 'bg'}>
            {
              selectedExch.exchJSON !== null ?
              <FAQ exchCode={selectedExch.exchCode} />
              :
              null
            }
            <Container>
              <Col lg="12">
                {userSessionExpired === false ? (
                  <div className="mt-3 mb-3">
                    <EditIcon />
                    <Button
                      onClick={() => setOpenFAQTextArea(true)}
                      style={{ backgroundColor: 'transparent', border: 'none' }}
                    >
                      <Text color="#3377C6" variant="small">
                        {t(`suggestEdit`)}
                      </Text>
                    </Button>
                  </div>
                ) : null}

                {openFAQTextArea === true ? (
                  <Row>
                    <Select
                      className="mb-3 mt-3"
                      isSearchable={false}
                      options={editDescOptions}
                      borderRadius={0}
                      defaultValue={editDescOptions[1]}
                      onChange={option => setEditType(option.value)}
                      components={{ IndicatorSeparator: () => null }}
                      userDarkMode={userDarkMode}
                    />
                    <Input
                      onChange={e => onChange(e)}
                      placeholder="Enter your suggestion..."
                    />
                    <Button
                      className="mt-3"
                      onClick={() => sendUserInput(inputValue)}
                      style={{ backgroundColor: 'transparent', border: 'none' }}
                    >
                      <Text color="#3377C6" variant="small">
                        {t(`send`)}
                      </Text>
                    </Button>
                  </Row>
                ) : null}
              </Col>
            </Container>
          </Section>
          <LatestNews />
        </PageWrapper>
      </>
    )
  } else return <Text>Loading...</Text>
}
export default ExchDetail
